import React, { useMemo } from 'react'
import { Modal } from 'antd'
import {
  useLocation,
  useNavigate
} from 'react-router'
import { useAuth } from 'hooks/useAuth'
import { RoleOperator } from 'utils/constants'
import {
  AiOutlineDollar,
  AiOutlineExclamationCircle,
  AiOutlineFileDone,
  AiOutlineFolderOpen,
  AiOutlineHome,
  AiOutlineInbox,
  AiOutlineLogout,
  AiOutlineSetting,
  AiOutlineShoppingCart,
  AiOutlineSnippets,
  AiOutlineUser,
  AiOutlineUsergroupDelete,
  AiOutlineUserSwitch
} from 'react-icons/ai'
import {TbPointerStar, TbReportSearch, TbSettingsStar, TbStar} from 'react-icons/tb'
import { useLogin } from 'repositories'
import Profile from '../Header/Profile'
import Group from './Group'

import {
  Container,
  Content,
  Divider,
  Item
} from './styles'

const { confirm } = Modal

export type TItem = {
  path?: string
  title: string
  active: boolean
  icon?: React.ReactNode
  permission?: string
  onClick?: () => void
}

type TMenuItems = TItem & {
  multiLevel?: boolean
  items?: TItem[]
}

const accessFreeMenuItems: TMenuItems[] = [
  {
    title: 'Requisições',
    path: '/',
    active: false,
    icon: <AiOutlineHome />,
    permission: `${RoleOperator.VIEW_REQUEST_SIMPLE}`
  }
]

const accessControledMenuItems: TMenuItems[] = [
  {
    title: 'Acompanhamentos',
    path: '/acompanhamentos',
    active: false,
    icon: <TbReportSearch />,
    permission: `${RoleOperator.REQUEST_TRACKING}`
  },
  {
    title: 'Aprovações',
    path: '/painel-aprovacoes',
    active: false,
    icon: <TbReportSearch />,
    permission: `${RoleOperator.REQUEST_APPROVALS}`
  },
  {
    title: 'Avaliações',
    path: '/avaliacoes',
    active: false,
    icon: <TbStar />,
    permission: `${RoleOperator.SUPPLIER_EVALUATION}`
  },
  {
    title: 'Cadastros',
    active: false,
    icon: <AiOutlineFolderOpen />,
    multiLevel: true,
    items: [
      {
        title: 'Unidades de Negócio',
        path: '/unidades-de-negocio',
        active: false,
        icon: <AiOutlineSnippets />,
        permission: RoleOperator.PROJECT_VIEW
      },
      {
        title: 'Clientes',
        path: '/clientes',
        active: false,
        icon: <AiOutlineUsergroupDelete />,
        permission: RoleOperator.CLIENT_VIEW
      },
      {
        title: 'Projetos',
        path: '/projetos',
        active: false,
        icon: <AiOutlineSnippets />,
        permission: RoleOperator.PROJECT_VIEW
      },
      {
        title: 'Produtos',
        path: '/produtos',
        active: false,
        icon: <AiOutlineInbox />,
        permission: RoleOperator.PRODUCT_VIEW
      },
      {
        title: 'Fornecedores',
        path: '/fornecedores',
        active: false,
        icon: <AiOutlineUserSwitch />,
        permission: RoleOperator.SUPPLIER_VIEW
      },
      {
        title: 'Centros de Custo',
        path: '/centros-de-custo',
        active: false,
        icon: <AiOutlineUserSwitch />,
        permission: RoleOperator.SUPPLIER_VIEW
      },
      {
        title: 'Alçadas',
        path: '/alcadas',
        icon: <AiOutlineFileDone />,
        active: false,
        permission: RoleOperator.APPROVALHIERARCHY_VIEW
      }
    ]
  },
  {
    title: 'Auxiliares',
    active: false,
    icon: <AiOutlineSetting />,
    multiLevel: true,
    items: [
      {
        title: 'Usuários',
        path: '/usuarios',
        icon: <AiOutlineUser />,
        active: false,
        permission: RoleOperator.USER_VIEW
      },
      {
        title: 'Grupos de Usuários',
        path: '/grupos-usuarios',
        icon: <AiOutlineFileDone />,
        active: false,
        permission: RoleOperator.GROUP_VIEW
      }
    ]
  },


  // {
  //   title: 'Painel de Cotações',
  //   path: '/cotacoes',
  //   active: false,
  //   icon: <AiOutlineDollar />,
  //   permission: RoleOperator.SUPPLIER_DEFAULT
  // },
  // {
  //   title: 'Compra direta',
  //   path: '/compra-direta',
  //   active: false,
  //   icon: <AiOutlineShoppingCart />,
  //   permission: RoleOperator.PURCHASE_ORDER_VIEW
  // },
  // {
  //   title: 'Requisição de Compra',
  //   path: '/requisicao-compras',
  //   active: false,
  //   icon: <AiOutlineShoppingCart />,
  //   permission: `${RoleOperator.PROJECT_VIEW}|${RoleOperator.REQUEST_BUYER}`
  // },
  // {
  //   title: 'Painel de aprovações',
  //   path: '/painel-aprovacoes',
  //   active: false,
  //   icon: <AiOutlineFileDone />,
  //   permission: RoleOperator.APPROVE_DEFAULT
  // },

]

type TProps = {
  isSidebarOpen: boolean
}

const Sidebar = ({ isSidebarOpen }: TProps) => {
  const repositoryLogin = useLogin()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const {
    removeUserData,
    userData,
    roles,
    logoutKeycloakAd
  } = useAuth()
  const mainPath = `/${pathname.split('/')[1]}`

  const updatedMenuItems = useMemo(() => {
    const filterAllowedItems = () => {
      if (roles?.role_admin) {
        return [
          ...accessFreeMenuItems,
          ...accessControledMenuItems
        ]
      }

      const filterSingleLevelMenu = accessControledMenuItems.filter(item => {
        if (item.items?.length) return item

        const { authorities } = userData

        if (item.permission && item.permission?.includes('|')) {
          const [first, second] = item.permission.split('|')

          return authorities?.includes(first) || authorities?.includes(second)
        }

        return authorities?.includes(String(item?.permission))
      })

      const filterMultLevelMenu = filterSingleLevelMenu.map(item => {
        if (!item?.items?.length) return item

        const filterMultLevelItems = item.items.filter(subItem => (
          userData?.authorities?.includes(String(subItem?.permission))
        ))

        return { ...item, items: filterMultLevelItems }
      })
      return [
        ...accessFreeMenuItems,
        ...filterMultLevelMenu
      ]
    }

    if (!userData) return
    const filteredItems = filterAllowedItems()

    return filteredItems.map(menuItem => (
      mainPath === menuItem.path
        ? { ...menuItem, active: true }
        : menuItem
    ))
  }, [roles, userData, mainPath])

  const onClickSystemExit = async () => {
    if (userData && !userData.isActiveDirectory) await repositoryLogin.logout()
    removeUserData()
    navigate('/login')
  }

  const onClickSystemExitAndLogoutAd = async () => {
    removeUserData()
    if (userData && userData.isActiveDirectory) await logoutKeycloakAd()
    navigate('/login')
  }

  const showConfirmExit = () => {
    confirm({
      title: 'Deseja sair do sistema?',
      okText: 'Sim',
      icon: (
        <AiOutlineExclamationCircle
          size={18}
          color='orange'
        />
      ),
      async onOk() {
        if (userData.isActiveDirectory) {
          await onClickSystemExitAndLogoutAd()
        }

        onClickSystemExit()
      },
    })
  }

  return (
    <Container
      isSidebarOpen={isSidebarOpen}
    >
      <Content>
        <Profile />
      </Content>
      {updatedMenuItems?.map(menuItem => {
        const {
          title,
          path,
          icon,
          active,
          multiLevel,
          items
        } = menuItem

        return (
          <div key={title}>
            {multiLevel ? (
              Boolean(items?.length) && (
                <Group
                  isSidebarOpen={isSidebarOpen}
                  key={title}
                  title={title}
                  icon={icon}
                  items={items ?? []}
                />
              )
            ) : (
              <Item
                title={title}
                key={title}
                to={String(path)}
                $active={active}
                onClick={() => {
                  if (mainPath === path) {
                    window.location.href = path;
                  }
                }}
              >
                {icon}
                <span>
                  {title}
                </span>
              </Item>
            )}
          </div>
        )
      })}
      <Divider />
      <Item
        to={mainPath}
        title='Sair'
        onClick={showConfirmExit}
      >
        <AiOutlineLogout />
        <span>Sair</span>
      </Item>
    </Container>
  )
}
export default Sidebar
