import React, {useEffect, useState} from 'react'
import {
  Form,
  Drawer,
  Button,
  Space
} from 'antd'
import {
  Col,
  DateField, MaskedField,
  Row,
  SelectField, TextField
} from 'components'
import {useBusinessUnits, useClient, useProduct, useUsers} from "../../../../repositories";
import {DefaultOptionType} from "antd/lib/select";
import {formatDate} from "../../../../utils/helpers";

type TFilterListFilterSupplierParamsForm = {
  periodExpiration: string
  periodIssue: string
  category: string
  allocation: string
  buId: string
  projectIdERP: string
  clientId: string
  timing: string
  statusRequest: string
  requesterId: string
  productId: string
  typeProvider: string
  id: string
  supplierId: string
  supplierIdCnpj: string
  billingType: string
  costCenterId: string
}

const initialValues: TFilterListFilterSupplierParamsForm = {
  periodIssue: "",
  periodExpiration: "",
  category: "",
  allocation: "",
  buId: "",
  projectIdERP: "",
  clientId: "",
  timing: "",
  statusRequest: "",
  requesterId: "",
  productId: "",
  typeProvider: "",
  id: "",
  supplierId: "",
  supplierIdCnpj: "",
  billingType: "",
  costCenterId: ""
}

const optionsTypeProvider: any[] = [
  {
    label: "Fornecedor",
    value: "SUPPLIER",
  },
  {
    label: "Freelancer",
    value: "FREELANCE",
  },
];
const optionsBillingType: any[] = [
  {
    label: "TV1",
    value: "0",
  },
  {
    label: "Cliente",
    value: "1",
  },
];
const optionsTiming: any[] = [
  {
    label: "Planejada",
    value: "PLANEJADA",
  },
  {
    label: "Não planejada",
    value: "EMERGENCIAL",
  },
];
const optionsCategory: any[] = [
  {
    label: "Compra & Contratação",
    value: "Compra & Contratação",
  },
  {
    label: "Rateios e Contratos",
    value: "Rateios e Contratos",
  },
];
const optionsAllocation: any[] = [
  {
    label: "Com Projeto",
    value: "Com projeto",
  },
];
const optionsStatus: any[] = [
  {
    value: "NEW",
    label: "Novo",
  },
  {
    value: "ON_APPROVAL",
    label: "Em aprovação",
  },
  {
    value: "CANCELED",
    label: "Cancelada",
  },
  {
    value: "SUSPEND",
    label: "Suspensa",
  },
  {
    value: "APPROVED",
    label: "Aprovado",
  },
  {
    value: "REFUSED",
    label: "Reprovada",
  },
  {
    value: "PAID_OUT",
    label: "Pago",
  },
];

type TProps = {
  isVisibleDrawer: boolean
  setIsVisibleDrawer: React.Dispatch<boolean>,
  filters: TFiltersHook
  resetPagination: any
}

const FiltersForm = (props: TProps) => {

  const buRepository = useBusinessUnits();
  const userRepository = useUsers();
  const clientRepository = useClient();
  const productRepository = useProduct()

  const [optionsBU, setBUOptions] = useState<DefaultOptionType[]>([]);
  const [userOptions, setUserOptions] = useState<DefaultOptionType[]>([]);
  const [optionsClients, setClientsOptions] = useState<DefaultOptionType[]>([]);
  const [optionsProduct, setProductOptions] = useState<DefaultOptionType[]>([]);

  const {
    isVisibleDrawer,
    setIsVisibleDrawer,
    filters,
    resetPagination
  } = props
  const [form] = Form.useForm<TFilterListFilterSupplierParamsForm>()

  const handleClose = () => {
    setIsVisibleDrawer(false)
  }

  const handleSubmit = async () => {
    const validatedData = await form.validateFields()
    if (!validatedData) return

    resetPagination()
    onFinish(validatedData)
    handleClose()
  }

  useEffect(() => {
    requestBUOptions()
    requestClientsOptions()
    requestUserOptions()
    requestProductOptions()
    if (!filters.items?.length) form.resetFields()
  }, [filters.items, form])

  const onFinish = (values: TFilterListFilterSupplierParamsForm) => {
    const _values = Object.entries(values)
    const transformedObj = [] as any;

    if (Array.isArray(_values)) {
      _values.forEach(([key, value]) => {
        if (key === "periodIssue" && Array.isArray(value) && value.length > 0) {

          transformedObj.push(["startIssueDate", formatDate(value[0])]);
          transformedObj.push(["endIssueDate", formatDate(value[1])]);
        } else if (key === "periodExpiration" && Array.isArray(value) && value.length === 2) {
          transformedObj.push(["startMaturityDate", formatDate(value[0])]);
          transformedObj.push(["endMaturityDate", formatDate(value[1])]);
        } else {
          transformedObj.push([key, value]);
        }
      });
    }

    const filterNonNullValues = transformedObj.filter((value: [any, any]) => {
      const [, term] = value
      return Boolean(term)
    })
    const _filters = [] as TFilter[]
    filterNonNullValues.forEach((value: [any, any]) => {
      const [field, term] = value
      const cleanedTerm = typeof term === 'string' ? term.trim() : String(term)
      _filters.push({ field, term: cleanedTerm })
    })

    filters.clearAndAdd(_filters)
  }

  const onClickResetFilters = () => filters.clearAndAdd([])

  const requestBUOptions = async () => {
    const response = await buRepository.findBus({
      page: 0,
      size: 10000,
    });
    if (!response) return;

    const mapped = response.content.map((item: any) => ({ value: item.id, label: item.name }));
    mapped.sort((a: { label: string; }, b: { label: string; }) => a.label.localeCompare(b.label));

    if (!response) return;
    setBUOptions(mapped);
  };

  const requestUserOptions = async () => {

    const response = await userRepository.findByFilterUsers({
      page: 0,
      size: 10000,
    });
    if (!response) return;
    const mapped = response.content.map((item: any) => ({ value: item.id, label: item.name }));
    mapped.sort((a, b) => a.label.localeCompare(b.label));

    if (!response) return;

    setUserOptions(mapped);
  };


  const requestClientsOptions = async () => {

    const response = await clientRepository.findClientByFilter({
      page: 0,
      size: 10000,
    });
    if (!response) return;

    const mapped = response.content.map((item: any) => ({ value: item.id, label: item.corporateName }));
    mapped.sort((a, b) => a.label.localeCompare(b.label));

    if (!response) return;
    setClientsOptions(mapped);
  };
  const requestProductOptions = async () => {
    const response = await productRepository.findProductByFilter({
      page: 0,
      size: 10000,
    });
    if (!response) return;

    const mapped = response.content.map((item: any) => ({ value: item.id, label: item.description }));
    mapped.sort((a, b) => a.label.localeCompare(b.label));

    if (!response) return;
    setProductOptions(mapped);
  };

  return (
    <Drawer
      title='Filtros'
      width={500}
      onClose={handleClose}
      visible={isVisibleDrawer}
      destroyOnClose
    >
      <Form
        form={form}
        layout='vertical'
        onFinish={onFinish}
        initialValues={initialValues}
      >
        <Row>
          <Col md={12} xl={12} xxl={12}>
            <DateField name="periodIssue" label="Período de emissão" rangePicker={true}  />
          </Col>
          <Col md={12} xl={12} xxl={12}>
            <DateField name="periodExpiration" label="Período de Vencimento" rangePicker={true} />
          </Col>
        </Row>
        <Row>
          <Col md={12} xl={12} xxl={12}>
            <TextField name="id" label="ID da requisição" placeholder="Digite" />
          </Col>
          <Col md={12} xl={12} xxl={12}>
            <TextField name="idTotvs" label="Nº do pedido" placeholder="Digite" />
          </Col>
        </Row>
        <Row>
          <Col md={12} xl={12} xxl={12}>
            <SelectField
                showSearch
                options={userOptions}
                name="requesterId"
                label="Requisitante"
                optionFilterProp="label"
            />
          </Col>
          <Col md={12} xl={12} xxl={12}>
            <SelectField showSearch options={optionsProduct} name="productId" label="Produto da requisição" optionFilterProp="label" />
          </Col>
        </Row>
        <Row>
          <Col md={12} xl={12} xxl={12}>
            <TextField name="project" label="Nome ou ID do projeto (PSP)" placeholder="Digite" />
          </Col>
          <Col md={12} xl={12} xxl={12}>
            <SelectField showSearch options={optionsClients} name="clientId" label="Cliente" optionFilterProp="label" />
          </Col>
        </Row>
        <Row>
          <Col md={12} xl={12} xxl={12}>
            <SelectField options={optionsTypeProvider} name="typeProvider" label="Tipo de Prestador" />
          </Col>
          <Col md={12} xl={12} xxl={12}>
            <MaskedField mask='99.999.999/9999-99' name="supplierIdCnpj" label="CNPJ" placeholder="Digite" />
          </Col>
        </Row>
        <Row>
          <Col md={12} xl={12} xxl={12}>
            <TextField name="companyName" label="Razão social" placeholder="Digite" />
          </Col>
          <Col md={12} xl={12} xxl={12}>
            <TextField name="tradeName" label="Nome fantasia" placeholder="Digite" />
          </Col>
        </Row>
        <Row>
          <Col md={12} xl={12} xxl={12}>
            <SelectField options={optionsBU} name="buId" label="Local da Requisição" />
          </Col>
          <Col md={12} xl={12} xxl={12}>
            <SelectField options={optionsBillingType} name="billingType" label="Fatura da linha do projeto" />
          </Col>
        </Row>
        <Row>
          <Col md={12} xl={12} xxl={12}>
            <SelectField options={optionsCategory} name="category" label="Tipo da Requisição" />
          </Col>
          <Col md={12} xl={12} xxl={12}>
            <SelectField options={optionsStatus} name="statusRequest" label="Status da Requisição" />
          </Col>
        </Row>
        <Row>
          <Col md={12} xl={12} xxl={12}>
            <SelectField options={optionsTiming} name="timing" label="Timing" />
          </Col>
          <Col md={12} xl={12} xxl={12}>
            <SelectField options={optionsAllocation} name="allocation" label="Alocação da Requisição" />
          </Col>
        </Row>
        <Form.Item className='mt-1'>
          <Space size='small'>
            <Button
                onClick={handleSubmit}
                type='primary'
            >
              Filtrar
            </Button>
            <Button
                onClick={onClickResetFilters}
            >
              Limpar
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Drawer>
  )
}

export default FiltersForm
