import { useState } from "react";
import useRest from "hooks/useRest";
import {
  TCreatePurchaseOrder,
  TPurchaseOrder,
  TPurchaseOrderParams,
  TPurchaseOrderResponse,
  TUpdatePurchaseOrder,
  TApprovePurchaseOrderParams,
  TAssessPurchaseOrder,
} from "repositories";
import { map } from "lodash";

const usePurchaseOrder = () => {
  const [loading, setLoading] = useState(false);
  const rest = useRest();

  const findPurchaseOrderById = async (id: string, triggerLoading = true) => {
    if (triggerLoading) setLoading(true);
    const url = `/v1/manager/purchase-order/${id}`;
    const response = await rest.get(url);
    if (triggerLoading) setLoading(false);
    if (!response) return {} as TPurchaseOrder;

    return response.result as TPurchaseOrder;
  };

  const findPurchaseOrderByFilter = async (
    params: TPurchaseOrderParams,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true);
    const url = "/v1/manager/purchase-order";
    const response = await rest.get(url, params);
    if (triggerLoading) setLoading(false);
    if (!response) return {} as TPurchaseOrderResponse;

    const mappedData = response.result.content.map((order: any) => {
      console.log("ProjectRequest:", order.projectRequest);
      return {
      ...order,
      cancellationReason: order.projectRequest?.descriptionSuspended || "Motivo não informado", 
      cancellationDate: order.projectRequest?.dateTimeSuspended || null, 
      }
    });

    return {
    ...response.result,
    content: mappedData,
    }as TPurchaseOrderResponse;
  };

  const createPurchaseOrder = async (
    data: TCreatePurchaseOrder,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true);
    const url = "/v1/manager/purchase-order";
    const response = await rest.post(url, data);
    if (triggerLoading) setLoading(false);
    if (!response) return undefined;

    return response.result as TPurchaseOrder;
  };

  const updatePurchaseOrder = async (
    data: TUpdatePurchaseOrder,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true);
    const url = "/v1/manager/purchase-order";
    const response = await rest.put(url, data);
    if (triggerLoading) setLoading(false);
    if (!response) return undefined;

    return response.result as TPurchaseOrder;
  };

  const sendToAnalysisPurchaseOrder = async (
    id: string,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true);
    const url = `/v1/manager/purchase-order/send-to-analysis/${id}`;
    const response = await rest.put(url);
    if (triggerLoading) setLoading(false);
    if (!response) return {} as TPurchaseOrder;

    return response.result as TPurchaseOrder;
  };

  const getPurchaseOrderInAnalysis = async (
    params: TPurchaseOrderParams,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true);
    const url = "/v1/approve/purchase-order";
    const response = await rest.get(url, params);
    if (triggerLoading) setLoading(false);
    if (!response) return {} as TPurchaseOrderResponse;

    return response.result as TPurchaseOrderResponse;
  };

  const assessPurchaseOrder = async (
    params: TApprovePurchaseOrderParams,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true);
    const url = "/v1/approve/purchase-order";
    const response = await rest.post(url, params);
    if (triggerLoading) setLoading(false);
    if (!response) return {} as TAssessPurchaseOrder;

    return response.result as TAssessPurchaseOrder;
  };

  const sendApprovedOrderToERP = async (id: number, triggerLoading = true) => {
    if (triggerLoading) setLoading(true);
    const url = `/v1/manager/purchase-order/send-to-erp/${id}`;
    const response = await rest.post(url);
    if (triggerLoading) setLoading(false);
    if (!response) return false;

    return true;
  };

  const cancelPurchaseOrder = async (id: number, triggerLoading = true) => {
    if (triggerLoading) setLoading(true);
    const url = `/v1/manager/purchase-order/cancel/${id}`;
    const response = await rest.put(url);
    if (triggerLoading) setLoading(false);
    if (!response) return false;

    return true;
  };

  return {
    loading,
    findPurchaseOrderByFilter,
    findPurchaseOrderById,
    createPurchaseOrder,
    updatePurchaseOrder,
    sendToAnalysisPurchaseOrder,
    getPurchaseOrderInAnalysis,
    assessPurchaseOrder,
    sendApprovedOrderToERP,
    cancelPurchaseOrder,
  };
};

export default usePurchaseOrder;
